<template>
    <div v-if="show" class="modal-overlay" @click="outsideClick($event)">
        <img :src="src" alt="Imagen" class="modal-image" v-if="type === 'image'"/>
        <div class="player-container" v-if="type === 'video'" style="min-width: 30%;">
            <vue-core-video-player :src="src"></vue-core-video-player>
        </div>        
    </div>
</template>
  
  <script>
  export default {
    props: {
      show: Boolean,
      type: String,
      src: String
    },
    components:{
    },
    methods: {
      close() {
        this.$emit('close');
      },
      outsideClick(event) {
        if (event.target === this.$el) {
            this.close();
        }
      }
    }
  };
  </script>
  
  <style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 60px;
  color: white; /* Color blanco */
  cursor: pointer;
  z-index: 1; /* Asegura que la "x" esté por encima de la imagen */
}

.modal-image {
  max-height: 85%;
  max-width: 85%;
  object-fit: contain;
}
</style>