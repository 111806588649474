import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import LoginPage from './views/Login.vue';
import ChatPage from './views/Chat.vue';
import ChatPageAdmin from './views/ChatAdmin.vue';
import Unauthorized from './views/Unauthorized.vue';
import VueCoreVideoPlayer from 'vue-core-video-player';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueCoreVideoPlayer);
Vue.use(VueRouter);
Vue.config.productionTip = false;

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: LoginPage, },
    { path: '/chat', component: ChatPage, },
    { path: '/chatAdmin', component: ChatPageAdmin, },
    { path: '/unauthorized', component: Unauthorized, },
  ]
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
