<template>
  <div class="search-box">
    <input type="text" :placeholder="pholder" v-model="searchText" @input="emitSearchText" />
    <font-awesome-icon class="iconoSearchInput" icon="fa-solid fa-magnifying-glass" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: '' // Propiedad para almacenar el texto de búsqueda
    };
  },
  props: {
    pholder: String
  },
  methods: {
    emitSearchText() {
      this.$emit('search', this.searchText); // Emitir evento 'search' con el texto de búsqueda
    }
  },
};
</script>

<style scoped>
.search-box {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 20px;
}

input[type="text"] {
  padding-left: 40px;
  border: none;
  background-color: #F6F6F6;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 10px;
  width: 100%;
  left: 32px;
  height: 50px;
  outline: none;
  color: #AAAAAA;
  font-size: 16px;
  font-family: 'Montserrat', 'regular';
}

input[type="text"]::placeholder {
  color: #AAAAAA;
}

.iconoSearchInput {
  position: absolute;
  left: 30px;
  top: 33px;
  color: #00000029;
  font-size: 22px;
}
</style>