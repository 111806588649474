<template>
  <div id="app" class="containerxd" @click="canUseSound()">
    <div class="chat-list">
      <span class="btn btn-primary" style="margin-top: 15px;margin-right: 15px;background-color: gray;border-color: gray;float: right;" @click="goToAdmin()" v-if="isAdmin">
        Admin
        <font-awesome-icon
            class="iconoSendMessagesInput"
            icon="fa-solid fa-arrow-right"
        />
      </span>
      <search-input @search="handleSearchInput" pholder="Buscar chat"/>
      <ul style="padding-left: 10px; padding-right: 20px">
        <li v-for="(chat, index) in chatList" :key="index" @click="selectChat(chat)" style="cursor: pointer">
          <img
            src="https://img.freepik.com/foto-gratis/belleza-natural_1098-16659.jpg"
            alt="Profile Picture"
            class="avatar"
            v-if="chat.showSearch"
          />
          <div class="chat-info" v-if="chat.showSearch">
            <div class="chat-name">
              {{ chat.name }}
              <span style="float: right;font-family: 'Montserrat', 'regular';font-size: 14px;" v-if="chat.chat.lastMessage">{{convertirTimestampAFecha(chat.chat.lastMessage.timestamp)}}</span>
            </div>            
            <div class="chat-phone">+{{ chat.phoneNumber }}</div>
            <div class="chat-message" v-if="chat.chat.lastMessage" :class="{ 'read': chat.chat.lastMessage.read }" :data-unread-count="chat.chat.lastMessage.unreadCount">
              {{ getMessageWithDots(chat.chat.lastMessage.message) }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="chat-details"
      style="display: flex; flex-direction: column; height: 100%"
    >
      <div class="chat-details-header" v-if="selectedChat.name">
        <img
          src="https://img.freepik.com/foto-gratis/belleza-natural_1098-16659.jpg"
          alt="Profile Picture"
          class="avatar"
          style="height: 60px; width: 60px"
        />
        <div class="chatNameHeader">{{ selectedChat.name }}</div>
      </div>
      <div class="chat-messages" ref="chatMessagesRef">
        <li
          v-for="(message, index) in selectedChat.chat.chat"
          :key="index"
          :class="{
            'sent-message': message.sender === 'me',
            'received-message': message.sender === 'you',
          }"
        >
          <div
            v-if="message.timestamp && message.sender"
            :class="{
              'message-content message-me': message.sender === 'me',
              'message-content message-you': message.sender === 'you',
            }"
          >
            <div v-if="message.type == 'text'">
              {{ message.message }}
            </div>
            <div v-if="message.type == 'image'">
              <img :src="message.filepath" alt="Imagen" width="100px" @click="openModalVideoImage(message.filepath,'image')"/>
            </div>
            <div v-if="message.type == 'sticker'">
              <img :src="message.filepath" alt="Sticker" width="100px" />
            </div>
            <div v-if="message.type == 'audio'">
              <audio controls>
                <source :src="message.filepath" type="audio/aac">
                Your browser does not support the audio tag.
              </audio>
            </div>
            <div v-if="message.type == 'video'">
              <img
                @click="openModalVideoImage(message.filepath,'video')"
                src="../assets/resources/video.png"
                alt="video"
                width="80px"
                style="cursor:pointer;"
              /><br />
              <span>Reproducir Video</span>
            </div>
            <div v-if="message.type === 'document'">
              <img
                :src="getImageDocument(message.document.mime_type)"
                :alt="message.document.filename"
                width="80px"
              /><br />
              <a :href="message.filepath" target="_blank">{{
                message.document.filename
              }}</a>
            </div>
            <div v-if="message.type === 'template'">
              Se ha enviado un mensaje predeterminado.
            </div>
          </div>
          <div
            v-if="message.timestamp && message.sender"
            :class="{
              'message-content timestamp-me': message.sender === 'me',
              'message-content timestamp-you': message.sender === 'you',
            }"
          >
            {{ convertirTimestampAFecha(message.timestamp) }}
            <font-awesome-icon
              v-if="message.sender === 'me'"
              :class="message.status === 'read' ? 'iconoSendMessagesInput blueCheck' : message.status === 'failed' ? 'iconoSendMessagesInput failedCheck' :  'iconoSendMessagesInput'"
              :icon="getStatusMessageIcon(message.status)"
            />
            <br v-if="message.errors && message.status === 'failed'">
            <span class="btn" v-if="message.errors && message.status === 'failed' && message.errors.code === 131047" style="background-color: red;color: white;width: 95px;font-size: 10px;" @click="sendReContactTemplate()">Re-contactar</span>
          </div>
        </li>
      </div>
      <div class="send-messages-box" v-if="selectedChat.chat.chat">
        <input
          type="text"
          placeholder="Escribe un mensaje"
          v-model="wspinput"
          v-on:keyup.enter="sendMessage"
          :disabled="selectedFile"
        />
        <font-awesome-icon
          class="iconoSendMessagesInput"
          icon="fa-solid fa-face-grin-wide"
          @click="showSelectorEmojisClick()"
        />
        <font-awesome-icon
          class="iconoSendFileInput"
          icon="fa-solid fa-file-lines"
          @click="selectFile()"
        />
        <input type="file" ref="inputSelectFile" @change="handleFileSelect" hidden/>
        <VEmojiPicker
          v-if="showSelectorEmojis"
          @select="selectEmoji"
          :showSearch="false"
          :showCategories="true"
          class="iconoSendMessagesInput"
          style="position: absolute;top: -420px;left: 25px;border-radius: 30px 30px 30px 0px;height: 425px;width: 335px;"
        />
        <button class="trash-button" v-if="selectedFile" @click="removeSelectedFile()">
          <span style="font-family: 'Montserrat', 'regular'">
            <font-awesome-icon icon="fa-solid fa-trash"/>
          </span>
        </button>
        <button
          class="send-button"
          v-on:click="sendMessage"
          style="width: 150px"
        >
          <span style="font-family: 'Montserrat', 'regular'"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            />&nbsp;&nbsp;Enviar</span
          >
        </button>
      </div>
      <ModalImage :show="showModalImage" :type="typeSrc" :src="src" @close="closeModalImage" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import app from "../firebaseInit";
import { VEmojiPicker } from 'v-emoji-picker';
import searchInput from "../components/searchInput.vue";
import ModalImage from "../components/ModalImage.vue";
import { getFirestore, onSnapshot, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import notificacionSound from '../assets/soundNotification/notification.mp3';
export default {
  data() {
    return {
      chatList: [],
      selectedChat: {
        chat: [],
      },
      chat: [],
      wspinput: "",
      tokenWsp: null,
      imageURL: null,
      urlBase: 'https://webhookmeta.vgroup.cl',
      showSelectorEmojis: false,
      showModalImage: false,
      src: null,
      typeSrc:null,
      selectedFile:null,
      emailEjecutiva:null,
      wspPhoneId:null,
      totalUnreadCount:0,
      canUseSoundBool: false,
      isAdmin:false,
    };
  },
  components: {
    searchInput,
    VEmojiPicker,
    ModalImage
  },
  methods: {
    canUseSound(){
      this.canUseSoundBool = true;
    },
    reproducirNotificacion() {
      const audio = new Audio(notificacionSound);
      audio.play();
    },
    modifyTitleNotification(count){
      const pattern = /^\(\d+\)/;
      if(count === 0 || pattern.test(document.title)){
        document.title = document.title.replace(pattern, count === 0 ? "" : "(" + count + ")");
      } else {
        document.title = "(" + count + ") " + document.title;
      }
    },
    handleSearchInput(searchText) {
      this.chatList.forEach(chat => {
        const matchByPhoneNumber = chat.phoneNumber.includes(searchText);
        const matchByName = chat.name.toLowerCase().includes(searchText.toLowerCase());

        chat.showSearch = matchByPhoneNumber || matchByName;
      });
    },
    initListenerFirebase(){
      const db = getFirestore(app);
      const docRef = doc(db, "coordinadoras", this.emailEjecutiva);

      onSnapshot(docRef, (doc) => {
        const data = doc.data();
        if(data){
          Object.entries(data).forEach(([key, value]) => {
            if (value.type === "chat") {
              const index = this.chatList.findIndex(
                (item) => item.phoneNumber === key
              );
              if (value.chat) {
                value.chat = Object.entries(value.chat);
                value.chat = value.chat.filter(item => item[1].timestamp && item[1].type);
                value.chat = value.chat.sort((a, b) => a[1].timestamp - b[1].timestamp) ?? [];
                value.chat = Object.fromEntries(value.chat);
              } else {
                value.chat = [];
              }
              if (index !== -1) {
                this.chatList[index].chat = value;
                setTimeout(() => {
                  const chatMessagesRefContainer = this.$refs.chatMessagesRef;
                  if(chatMessagesRefContainer){
                    chatMessagesRefContainer.scrollTop = chatMessagesRefContainer.scrollHeight + 1000;
                  }
                }, 100);
              } else {
                this.chatList.push({
                  phoneNumber: key,
                  name: value.name ?? "+"+key,
                  chat: value,
                  showSearch: true,
                });
              }

              let previousUnreadCount = this.totalUnreadCount;
              this.totalUnreadCount = 0;
              this.chatList.map((chat)=>{
                if(chat.chat.lastMessage.unreadCount && !chat.chat.lastMessage.read){
                  this.totalUnreadCount += chat.chat.lastMessage.unreadCount;
                }
              });
              if(this.canUseSoundBool){
                if(this.totalUnreadCount > previousUnreadCount){
                  this.reproducirNotificacion();
                }
              }
              if(this.totalUnreadCount){
                this.modifyTitleNotification(this.totalUnreadCount);
              }
            }
          });
        }
      });
    },
    async selectChat(chat) {
      this.selectedChat = chat;
      setTimeout(() => {
        const chatMessagesRefContainer = this.$refs.chatMessagesRef;
        if(chatMessagesRefContainer){
          chatMessagesRefContainer.scrollTop = chatMessagesRefContainer.scrollHeight;
        }
      }, 400);
    },
    async sendMessage() {
      if(this.showSelectorEmojis){
        this.showSelectorEmojis = false;
      }
      if(!this.selectedFile){
        if (this.wspinput === "") return;
        let dataPost = {
          coordinadora: this.emailEjecutiva,
          msg: this.wspinput,
          phone: this.selectedChat.phoneNumber,
        };
        this.wspinput = "";
        await axios.post(this.urlBase + "/send-message", dataPost, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": this.tokenWsp
          }
        }).catch((e) => console.log(e));
      }else{
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('phone', this.selectedChat.phoneNumber);
        formData.append('coordinadora', this.emailEjecutiva);
        this.wspinput = "";
        await axios.post(this.urlBase + "/send-message-file",formData,{
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": this.tokenWsp
          }
        }).catch((e) => console.log(e));
        this.selectedFile = false;
        this.$refs.inputSelectFile.value = '';
      }
    },
    convertirTimestampAFecha(timestamp) {
      if(timestamp) {
        const seconds = timestamp.seconds;
        const milliseconds = seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
        const fecha = new Date(milliseconds);
  
        const horas = fecha.getHours().toString().padStart(2, "0");
        const minutos = fecha.getMinutes().toString().padStart(2, "0");
  
        return `${horas}:${minutos}`;
      } else {
        return '';
      }
    },
    getExtensionFile(mimeType) {
      if (mimeType.includes("/")) {
        return mimeType.split("/").pop();
      } else {
        return null;
      }
    },
    getImageDocument(mimeType) {
      if (mimeType === "application/pdf") {
        return require("../assets/resources/pdf.png");
      } else if (mimeType === "rar") {
        return require("../assets/resources/rar.png");
      } else if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        return require("../assets/resources/excel.png");
      } else if (mimeType === "image/jpeg") {
        return require("../assets/resources/jpg.png");
      } else if (mimeType === "video/mp4") {
        return require("../assets/resources/video.png");
      } else if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return require("../assets/resources/doc.png");
      } else if (mimeType === "application/x-rar-compressed") {
        return require("../assets/resources/rar.png");
      }
      return require("../assets/resources/doc.png");
    },
    getStatusMessageIcon(status){
      if(status === 'sent') {
        return 'fa-solid fa-check';
      } else if(status === 'failed') {
        return 'fa-solid fa-circle-xmark';
      } else {
        return 'fa-solid fa-check-double';
      }
    },
    getMessageWithDots(msg){
      if(!msg){
        return '';
      }
      if (msg.length > 25) {
        return msg.substring(0, 25) + "...";
      }
      return msg;
    },
    selectEmoji(emoji) {
      this.wspinput += emoji.data;
    },
    showSelectorEmojisClick(){
      this.showSelectorEmojis = !this.showSelectorEmojis;
    },
    openModalVideoImage(url,type) {
      this.typeSrc = type;
      this.src = url;
      this.showModalImage = true;
    },
    closeModalImage() {
      this.showModalImage = false;
    },
    selectFile() {
      this.$refs.inputSelectFile.click();
    },
    handleFileSelect(event){
      const file = event.target.files[0];
      if(file){
        this.selectedFile = file;
        this.wspinput = file.name;
      }
    },
    removeSelectedFile(){
      this.wspinput = '';
      this.selectedFile = false;
      this.$refs.inputSelectFile.value = '';
    },
    async sendReContactTemplate(){
      const dataTemplate = {
        sender: this.wspPhoneId,
        target: this.selectedChat.phoneNumber,
        template_name: "hello_world",
      };
      await axios.post(this.urlBase + "/send-template", dataTemplate, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": this.tokenWsp
        }
      });
    },
    goToAdmin(){
      this.$router.push('/chatAdmin');
    }
  },
  async created() {
    this.modifyTitleNotification(this.totalUnreadCount);
    const rsp = await axios.post(this.urlBase + "/verify-token", {
        token: localStorage.getItem("tokenWsp"),
    }).catch(() => {
        localStorage.removeItem('tokenWsp');
        this.$router.push('/unauthorized');
    });
    this.tokenWsp = localStorage.getItem("tokenWsp");
    if (rsp.status === 200 && rsp.data.data) {
      if(rsp.data.data.user.detalleUsuario.wspAdmin){
        this.isAdmin = true;
      }
      let emailEjecutiva = rsp.data.data.user.email.replace(/[^\w]+/g, '_').replace('.', '_');
      this.emailEjecutiva = emailEjecutiva;
      this.wspPhoneId = rsp.data.data.user.detalleUsuario.wspPhoneId;
      if(rsp.data.data.user.detalleUsuario.wspPhoneId && this.wspPhoneId){
        const db = getFirestore(app);
        const docRef = doc(db, "coordinadoras", this.emailEjecutiva);

        const dataCoordinadora = (await getDoc(docRef)).data();
        if(dataCoordinadora) {
          if(!dataCoordinadora.telefono || dataCoordinadora.telefono !== this.wspPhoneId){
            await updateDoc(docRef, {
              telefono: this.wspPhoneId,
            });
          }
          this.initListenerFirebase();
        }else{
          await setDoc(docRef, {});
        }
      }
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  display: flex;
  height: 100vh;
}

.send-messages-box {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.send-messages-box > input[type="text"] {
  padding-left: 100px;
  border: none;
  background-color: #f6f6f6;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 20px 0px 0px 20px;
  width: 85%;
  left: 32px;
  height: 50px;
  outline: none;
  color: #aaaaaa;
  font-size: 16px;
  font-family: "Montserrat", "regular";
}

.send-messages-box > input[type="text"]::placeholder {
  color: #aaaaaa;
}

.send-messages-box > .iconoSendMessagesInput {
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 22px;
  padding: 13px;
  background-color: white;
  border-radius: 12px 0px 0px 12px;
  border: 1px solid #ececec;
}

.iconoSendFileInput{
  position: absolute;
  left: 69px;
  top: 10px;
  font-size: 22px;
  padding: 13px;
  background-color: white;
  border-radius: 0px 12px 12px 0px;
  border: 1px solid #ececec;
}

.chat-list {
  width: 25%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
}

.chat-list::-webkit-scrollbar {
  width: 7px;
}
.chat-list::-webkit-scrollbar-track {
  background-color: #ededed;
}
.chat-list::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 5px;
}
.chat-list::-webkit-scrollbar-thumb:hover {
  background-color: #757575;
}

.chat-list-items {
  width: 20%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
}

.chat-list-items::-webkit-scrollbar {
  width: 7px;
}
.chat-list-items::-webkit-scrollbar-track {
  background-color: #ededed;
}
.chat-list-items::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 5px;
}
.chat-list-items::-webkit-scrollbar-thumb:hover {
  background-color: #757575;
}

.chat-messages::-webkit-scrollbar {
  width: 7px;
}
.chat-messages::-webkit-scrollbar-track {
  background-color: #ededed;
}
.chat-messages::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 5px;
}
.chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #757575;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  padding: 10px;
}

.avatar {
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.avatar2 {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.chat-name {
  text-align: left;
  font-size: 19px;
  letter-spacing: 0px;
  color: #191919;
  opacity: 1;
  font-family: "Montserrat", "regular";
  font-weight: bold;
}

.chat-phone {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #191919;
  opacity: 1;
  font-family: "Montserrat", "regular";
  font-weight: 500;
}

.chat-message {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #515151;
  opacity: 1;
  font-family: "Montserrat", "regular";
}

.chat-info {
  flex: 1;
  margin-left: 15px;
}

.chat-messages {
  flex: 1;
  padding: 20px;
}

.chat-messages p {
  margin-bottom: 10px;
}

.chat-form {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  height: 60px;
  padding: 0 20px;
}

.chat-form input {
  flex: 1;
  border: none;
  height: 40px;
  padding: 10px;
}

.chat-form button {
  background-color: #075e54;
  color: #fff;
  border: none;
  height: 40px;
  width: 60px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}
.chat-details {
  flex: 1;
  background-color: #f6f6f6;
  background-size: cover;
  height: 100%;
}
.chat-messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}

.sent-message,
.received-message {
  display: inline-block;
  max-width: 80%;
  word-wrap: break-word;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: "Montserrat", "regular";
}

.message-me {
  padding: 10px 15px 10px 15px;
  text-align: left;
  font-weight: 500;
}

.timestamp-me {
  font-size: 14px;
  text-align: right;
  padding-right: 15px;
  padding-left: 15px;
}

.message-you {
  padding: 10px 15px 10px 15px;
  text-align: left;
  font-weight: 500;
}

.timestamp-you {
  font-size: 14px;
  text-align: right;
  padding-right: 15px;
  padding-left: 15px;
}

.sent-message {
  background-color: #c6eec8;
  align-self: flex-end;
}

.received-message {
  background-color: #ffffff;
  align-self: flex-start;
}
.chat-details-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  color: #191919;
  box-shadow: 0px 3px 6px #00000029;
}

.chatNameHeader {
  color: #191919;
  margin-left: 20px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  opacity: 1;
  font-family: "Montserrat", "regular";
  font-weight: bold;
}

.whatsapp-input {
  flex-grow: 1;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  background-color: #f2f2f2;
}

.trash-button {
  position: absolute;
  right: 140px;
  width: 80px;
  top: 10px;
  color: black;
  font-size: 16px;
  cursor: pointer;
  padding: 13px;
  background-color: red;
  color: #fff;
  border-radius: 25px 0px 0px 25px;
  border: 1px solid #ececec;
}

.send-button {
  position: absolute;
  right: 20px;
  top: 10px;
  color: black;
  font-size: 16px;
  cursor: pointer;
  padding: 13px;
  background-color: #00a884;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #ececec;
}

.send-button:hover {
  background-color: #00a884;
}

.send-button:focus {
  outline: none;
}
.blueCheck{
  color: #34b7f1;
}

.failedCheck{
  color: red;
}

.tooltip {
  position: absolute;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  z-index: 999;
}

.emoji-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.emoji-list li {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.chat-message:not(.read) {
  position: relative;
  font-weight: bold;
}

.chat-message:not(.read)::after {
  content: attr(data-unread-count);
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #00a884;
  color: white;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 50%;
}

</style>