<template>
    <div id="app">
        <div class="chat-list">
            <span class="btn btn-primary" style="margin-top: 15px;margin-left: 15px;background-color: gray;border-color: gray;" @click="backMyChat()">
                <font-awesome-icon
                    class="iconoSendMessagesInput"
                    icon="fa-solid fa-arrow-left"
                />
                Mi Chat
            </span>
            <search-input @search="handleSearchInput" pholder="Buscar ejecutiva"/>
            <ul style="padding-left: 10px; padding-right: 20px">
              <li v-for="(chatListE, index) in chatEjecutivasList" :key="index" @click="selectChatEjecutiva(chatListE)" style="cursor: pointer">
                <img
                  src="https://img.freepik.com/foto-gratis/belleza-natural_1098-16659.jpg"
                  alt="Profile Picture"
                  class="avatar"
                />
                <div class="chat-info">
                  <div class="chat-name">
                    {{ chatListE.name }}
                  </div>
                </div>
              </li>
            </ul>
        </div>
        <div class="chat-list-items" v-if="selectedEjecutiva">
            <search-input @search="handleSearchInput" pholder="Buscar chat"/>
            <ul style="padding-left: 10px; padding-right: 20px">
              <li v-for="(chat, index) in selectedEjecutiva.chats" :key="index" @click="selectChatNormal(chat)" style="cursor: pointer">
                <img
                  src="https://img.freepik.com/foto-gratis/belleza-natural_1098-16659.jpg"
                  alt="Profile Picture"
                  class="avatar2"
                />
                <div class="chat-info" v-if="chat.showSearch">
                    <div class="chat-name">
                      {{ chat.name }}
                      <span style="float: right;font-family: 'Montserrat', 'regular';font-size: 14px;" v-if="chat.chat.lastMessage">{{convertirTimestampAFecha(chat.chat.lastMessage.timestamp)}}</span>
                    </div>            
                    <div class="chat-phone">+{{ chat.phoneNumber }}</div>
                    <div class="chat-message" v-if="chat.chat.lastMessage" :class="{ 'read': chat.chat.lastMessage.read }" :data-unread-count="chat.chat.lastMessage.unreadCount">
                      {{ getMessageWithDots(chat.chat.lastMessage.message) }}
                    </div>
                </div>
              </li>
            </ul>
        </div>
        <div class="chat-details" v-if="selectedEjecutiva && selectedChat" style="max-height: 100%;min-height: 100%;">
            <div class="chat-details-header" v-if="selectedChat.name">
                <img
                  src="https://img.freepik.com/foto-gratis/belleza-natural_1098-16659.jpg"
                  alt="Profile Picture"
                  class="avatar"
                  style="height: 60px; width: 60px"
                />
                <div class="chatNameHeader">{{ selectedChat.name }}</div>
              </div>
              <div class="chat-messages" ref="chatMessagesRef" style="max-height: 82.5% !important;min-height: 82.5%;">
                <li
                  v-for="(message, index) in selectedChat.chat.chat"
                  :key="index"
                  :class="{
                    'sent-message': message.sender === 'me',
                    'received-message': message.sender === 'you',
                  }"
                >
                  <div
                    v-if="message.timestamp && message.sender"
                    :class="{
                      'message-content message-me': message.sender === 'me',
                      'message-content message-you': message.sender === 'you',
                    }"
                  >
                    <div v-if="message.type == 'text'">
                      {{ message.message }}
                    </div>
                    <div v-if="message.type == 'image'">
                      <img :src="message.filepath" alt="Imagen" width="100px" @click="openModalVideoImage(message.filepath,'image')"/>
                    </div>
                    <div v-if="message.type == 'sticker'">
                      <img :src="message.filepath" alt="Sticker" width="100px" />
                    </div>
                    <div v-if="message.type == 'audio'">
                      <audio controls>
                        <source :src="message.filepath" type="audio/aac">
                        Your browser does not support the audio tag.
                      </audio>
                    </div>
                    <div v-if="message.type == 'video'">
                      <img
                        @click="openModalVideoImage(message.filepath,'video')"
                        src="../assets/resources/video.png"
                        alt="video"
                        width="80px"
                        style="cursor:pointer;"
                      /><br />
                      <span>Reproducir Video</span>
                    </div>
                    <div v-if="message.type === 'document'">
                      <img
                        :src="getImageDocument(message.document.mime_type)"
                        :alt="message.document.filename"
                        width="80px"
                      /><br />
                      <a :href="message.filepath" target="_blank">{{
                        message.document.filename
                      }}</a>
                    </div>
                    <div v-if="message.type === 'template'">
                      Se ha enviado un mensaje predeterminado.
                    </div>
                  </div>
                  <div
                    v-if="message.timestamp && message.sender"
                    :class="{
                      'message-content timestamp-me': message.sender === 'me',
                      'message-content timestamp-you': message.sender === 'you',
                    }"
                  >
                    {{ convertirTimestampAFecha(message.timestamp) }}
                    <font-awesome-icon
                      v-if="message.sender === 'me'"
                      :class="message.status === 'read' ? 'iconoSendMessagesInput blueCheck' : message.status === 'failed' ? 'iconoSendMessagesInput failedCheck' :  'iconoSendMessagesInput'"
                      :icon="getStatusMessageIcon(message.status)"
                    />
                    <br v-if="message.errors && message.status === 'failed'">
                    <span class="btn" v-if="message.errors && message.status === 'failed' && message.errors.code === 131047" style="background-color: red;color: white;width: 95px;font-size: 10px;" @click="sendReContactTemplate()">Re-contactar</span>
                  </div>
                </li>
              </div>
              <div class="send-messages-box" v-if="selectedChat.chat.chat">
                <input
                  type="text"
                  placeholder="Escribe un mensaje"
                  v-model="wspinput"
                  v-on:keyup.enter="sendMessageAdmin"
                  :disabled="selectedFile"
                />
                <font-awesome-icon
                  class="iconoSendMessagesInput"
                  icon="fa-solid fa-face-grin-wide"
                  @click="showSelectorEmojisClick()"
                />
                <font-awesome-icon
                  class="iconoSendFileInput"
                  icon="fa-solid fa-file-lines"
                  @click="selectFile()"
                />
                <input type="file" ref="inputSelectFile" @change="handleFileSelect" hidden/>
                <VEmojiPicker
                  v-if="showSelectorEmojis"
                  @select="selectEmoji"
                  :showSearch="false"
                  :showCategories="true"
                  class="iconoSendMessagesInput"
                  style="position: absolute;top: -420px;left: 25px;border-radius: 30px 30px 30px 0px;height: 425px;width: 335px;"
                />
                <button class="trash-button" v-if="selectedFile" @click="removeSelectedFile()">
                  <span style="font-family: 'Montserrat', 'regular'">
                    <font-awesome-icon icon="fa-solid fa-trash"/>
                  </span>
                </button>
                <button
                  class="send-button"
                  style="width: 150px"
                  @click="sendMessageAdmin"
                >
                  <span style="font-family: 'Montserrat', 'regular'"
                    ><font-awesome-icon
                      icon="fa-solid fa-paper-plane"
                    />&nbsp;&nbsp;Enviar</span
                  >
                </button>
              </div>
              <ModalImage :show="showModalImage" :type="typeSrc" :src="src" @close="closeModalImage" />
        </div>
    </div>
</template>


<script>
    import axios from "axios";
    import app from "../firebaseInit";
    import { VEmojiPicker } from 'v-emoji-picker';
    import searchInput from "../components/searchInput.vue";
    import ModalImage from "../components/ModalImage.vue";
    import { getFirestore, onSnapshot, doc, getDoc, updateDoc, setDoc, getDocs, query, collection } from "firebase/firestore";
    export default {
       data() {
        return {
            chatEjecutivasList: [],
            chatList: [],
            selectedEjecutiva: null,
            selectedChat: {
             chat: [],
            },
            chat: [],
            wspinput: "",
            tokenWsp: null,
            imageURL: null,
            urlBase: 'https://webhookmeta.vgroup.cl',
            showSelectorEmojis: false,
            showModalImage: false,
            src: null,
            typeSrc:null,
            selectedFile:null,
            emailEjecutiva:null,
            wspPhoneId:null,
            totalUnreadCount:0,
            canUseSoundBool: false,
        }
       },
       components: {
        searchInput,
        VEmojiPicker,
        ModalImage
       },
       methods: {
        backMyChat(){
            this.$router.push('/chat');
        },
        handleSearchInput(searchText) {
            this.chatList.forEach(chat => {
                const matchByPhoneNumber = chat.phoneNumber.includes(searchText);
                const matchByName = chat.name.toLowerCase().includes(searchText.toLowerCase());

                chat.showSearch = matchByPhoneNumber || matchByName;
            });
        },
        selectChatEjecutiva(chatListE){
            if(this.selectedEjecutiva === chatListE){
                this.selectedEjecutiva = null;
            }else{
                this.emailEjecutiva = chatListE.id;
                this.selectedEjecutiva = chatListE;
            }
        },
        async selectChatNormal(chat) {
            this.selectedChat = chat;
            setTimeout(() => {
                const chatMessagesRefContainer = this.$refs.chatMessagesRef;
                if(chatMessagesRefContainer){
                chatMessagesRefContainer.scrollTop = chatMessagesRefContainer.scrollHeight;
                }
            }, 400);
        },
        reemplazarString(texto) {
            return texto.replace(/_vgroup/g, '@vgroup').replace(/vgroup_cl/g, 'vgroup.cl');
        },
        initListenerFirebaseInEjecutiva(ejecutiva){
            const db = getFirestore(app);
            const docRef = doc(db, "coordinadoras", ejecutiva);

            onSnapshot(docRef, (doc) => {
                const data = doc.data();
                if(data){
                    Object.entries(data).forEach(([key, value]) => {
                        const indexEjecutiva = this.chatEjecutivasList.findIndex((ej)=>ej.id === ejecutiva);
                        if (value.type === "chat") {
                            const index = this.chatEjecutivasList[indexEjecutiva].chats.findIndex(
                                (item) => item.phoneNumber === key
                            );

                            if (value.chat) {
                                value.chat = Object.entries(value.chat);
                                value.chat = value.chat.filter(item => item[1].timestamp && item[1].type);
                                value.chat = value.chat.sort((a, b) => a[1].timestamp - b[1].timestamp) ?? [];
                                value.chat = Object.fromEntries(value.chat);
                            } else {
                                value.chat = [];
                            }
                            if (index !== -1) {
                                this.chatEjecutivasList[indexEjecutiva].chats[index].chat = value;
                            } else {
                                this.chatEjecutivasList[indexEjecutiva].chats.push({
                                    phoneNumber: key,
                                    name: value.name ?? "+"+key,
                                    chat: value,
                                    showSearch: true,
                                });
                            }
                        } else {
                            if(key === 'telefono'){
                                this.chatEjecutivasList[indexEjecutiva].telefono = value;
                            }
                        }
                    });
                }
            });
        },
        convertirTimestampAFecha(timestamp) {
            if(timestamp) {
                const seconds = timestamp.seconds;
                const milliseconds = seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000);
                const fecha = new Date(milliseconds);
        
                const horas = fecha.getHours().toString().padStart(2, "0");
                const minutos = fecha.getMinutes().toString().padStart(2, "0");
        
                return `${horas}:${minutos}`;
            } else {
                return '';
            }
        },
        getExtensionFile(mimeType) {
            if (mimeType.includes("/")) {
                return mimeType.split("/").pop();
            } else {
                return null;
            }
        },
        getImageDocument(mimeType) {
            if (mimeType === "application/pdf") {
                return require("../assets/resources/pdf.png");
            } else if (mimeType === "rar") {
                return require("../assets/resources/rar.png");
            } else if (
                mimeType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                return require("../assets/resources/excel.png");
            } else if (mimeType === "image/jpeg") {
                return require("../assets/resources/jpg.png");
            } else if (mimeType === "video/mp4") {
                return require("../assets/resources/video.png");
            } else if (
                mimeType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
                return require("../assets/resources/doc.png");
            } else if (mimeType === "application/x-rar-compressed") {
                return require("../assets/resources/rar.png");
            }
            return require("../assets/resources/doc.png");
        },
        getStatusMessageIcon(status){
            if(status === 'sent') {
                return 'fa-solid fa-check';
            } else if(status === 'failed') {
                return 'fa-solid fa-circle-xmark';
            } else {
                return 'fa-solid fa-check-double';
            }
        },
        getMessageWithDots(msg){
            if(!msg){
                return '';
            }
            if (msg.length > 25) {
                return msg.substring(0, 25) + "...";
            }
            return msg;
        },
        openModalVideoImage(url,type) {
            this.typeSrc = type;
            this.src = url;
            this.showModalImage = true;
        },
        closeModalImage() {
            this.showModalImage = false;
        },
        selectFile() {
            this.$refs.inputSelectFile.click();
        },
        handleFileSelect(event){
        const file = event.target.files[0];
        if(file){
            this.selectedFile = file;
            this.wspinput = file.name;
        }
        },
        removeSelectedFile(){
            this.wspinput = '';
            this.selectedFile = false;
            this.$refs.inputSelectFile.value = '';
        },
        async sendReContactTemplate(){
            const dataTemplate = {
                sender: this.selectedEjecutiva.telefono,
                target: this.selectedChat.phoneNumber,
                template_name: "hello_world",
            };
            await axios.post(this.urlBase + "/send-template", dataTemplate, {
                headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": this.tokenWsp
                }
            });
        },
        async sendMessageAdmin() {
            if(this.showSelectorEmojis){
                this.showSelectorEmojis = false;
            }
            if(!this.selectedFile){
                if (this.wspinput === "") return;
                let dataPost = {
                coordinadora: this.emailEjecutiva,
                msg: this.wspinput,
                phone: this.selectedChat.phoneNumber,
                };
                this.wspinput = "";
                await axios.post(this.urlBase + "/send-message", dataPost, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": this.tokenWsp
                }
                }).catch((e) => console.log(e));
            }else{
                const formData = new FormData();
                formData.append('file', this.selectedFile);
                formData.append('phone', this.selectedChat.phoneNumber);
                formData.append('coordinadora', this.emailEjecutiva);
                this.wspinput = "";
                await axios.post(this.urlBase + "/send-message-file",formData,{
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": this.tokenWsp
                }
                }).catch((e) => console.log(e));
                this.selectedFile = false;
                this.$refs.inputSelectFile.value = '';
            }
        },
       },
       async mounted(){
            const rsp = await axios.post(this.urlBase + "/verify-token", {
                token: localStorage.getItem("tokenWsp"),
            }).catch(() => {
                localStorage.removeItem('tokenWsp');
                this.$router.push('/unauthorized');
            });
            this.tokenWsp = localStorage.getItem("tokenWsp");
            if (rsp.status === 200 && rsp.data.data) {
                if(rsp.data.data.user.detalleUsuario.wspAdmin === 0){
                    this.$router.push('/chat');
                }else{
                    const db = getFirestore(app);
                    const querySnapshot = await getDocs(query(collection(db, "coordinadoras")));
                    querySnapshot.forEach((doc) => {
                        this.chatEjecutivasList.push({
                            id: doc.id,
                            name: this.reemplazarString(doc.id),
                            chats: [],
                        });
                        this.initListenerFirebaseInEjecutiva(doc.id);
                    });
                }
            } else {
                localStorage.removeItem('tokenWsp');
                this.$router.push('/unauthorized');
            }
       } 
    }
</script>

<style scoped>
.centrado {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
text-align: center;
}

.circulo {
width: 50px;
height: 50px;
border: 3px solid #999;
border-top-color: #333;
border-radius: 50%;
animation: spin 1s infinite linear;
margin: 20px auto;
}

@keyframes spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

p {
color: #333;
font-size: 18px;
font-weight: bold;
}
</style>