<template>
    <div class="text-center">
        <div class="centrado">
            <p>No Autorizado</p>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style scoped>
.centrado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

p {
    color: #333;
    font-size: 18px;
    font-weight: bold;
}
</style>