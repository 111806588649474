<template>
    <div class="text-center">
        <div class="centrado">
            <div class="circulo"></div>
            <p>{{ loading ? 'Autenticando...' : 'Redirigiendo...' }}</p>
        </div>
    </div>
</template>


<script>
    import axios from 'axios';
    export default {
       data() {
        return {
            loading: true,
            urlBase: 'https://webhookmeta.vgroup.cl'
        }
       },
       methods: {
        redirectWithSetTimeout(route){
          setTimeout(()=>{
            this.$router.push(`/${route}`);
          },1000);
        }
       },
       async mounted(){
        if(this.$route.query.token){
            const rsp = await axios.post(this.urlBase + "/verify-token", {
                token: this.$route.query.token,
            }).catch(() => {
                localStorage.removeItem('tokenWsp');
                this.redirectWithSetTimeout('unauthorized');
            });
            setTimeout(()=>{
                if(rsp.status === 200){
                    this.loading = false;
                    localStorage.setItem('tokenWsp',rsp.data.token);
                    if(rsp.data.data.user.detalleUsuario.wspAdmin){
                        this.redirectWithSetTimeout('chatAdmin');
                    } else {
                        this.redirectWithSetTimeout('chat');
                    }
                }
            },1000);
        } else {
            this.loading = false;
            this.redirectWithSetTimeout('unauthorized');
        }
       } 
    }
</script>

<style scoped>
.centrado {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
text-align: center;
}

.circulo {
width: 50px;
height: 50px;
border: 3px solid #999;
border-top-color: #333;
border-radius: 50%;
animation: spin 1s infinite linear;
margin: 20px auto;
}

@keyframes spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

p {
color: #333;
font-size: 18px;
font-weight: bold;
}
</style>